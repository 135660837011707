import React, { createContext, useContext, useEffect, useState } from "react";
import NFTComponent from "./NFTComponent";
import { ToggleButton, ButtonGroup } from "react-bootstrap";
import { AppContext, } from "../App";
import Toggle from "./Toggle";
import axios from "axios";

export const ReloadContext = createContext()

const NFTsManager = () => {

    const [, , nftContractAddress, , , , ethAddress,] = useContext(AppContext)

    const [reload, setReload] = useState(false)

    const [NFTsArray, setNFTsArray] = useState([]);
    const [hasNFTs, setHasNFTs] = useState();
    const [loading, setLoading] = useState(true);

    const moralisAPIKEY = 'xFWG5rmoAlUXyS3E2m6GSpwmdwJMHPQJntQn5WXbJhGnKQWufmG9OFsngLSDx87y'

    const [newlyStaked, setNewlyStaked] = useState(null)

    const ipfs = "https://santafloki.mypinata.cloud/ipfs/";

    const ipfsURI = "QmYTZJgpMdbHuGJbAxSTwnwHbaMr98gU4RNqUdxbH6NnAW/"

    const [radioValue, setRadioValue] = useState('30');
    const radios = [
        { name: '30 Days', value: '30' },
        { name: '15 Days', value: '15' },
        { name: '60 Days', value: '60' },
        { name: '90 Days', value: '90' },
    ];

    useEffect(async () => {
        if (!window.ethereum && ethAddress) {
            await getUserNFTs();
        }
        else if (window.ethereum) {
            await getUserNFTs();
        }

    }, [reload, ethAddress]);

    async function getUserNFTs() {
        setLoading(true)
        let params = { chain: 'bsc', format: 'decimal', token_addresses: nftContractAddress, normalizeMetadata: 'false' }
        try {
            let nfts = await axios.get(`https://deep-index.moralis.io/api/v2/${ethAddress}/nft`, { params: params, headers: { accept: 'application/json', 'X-API-Key': moralisAPIKEY } })
            console.log(nfts)
            makeImageURI(nfts.data.result);
        }
        catch (err) {
            setLoading(false);
        }
    }

    async function makeImageURI(nfts) {
        const nftsarr = [];
        await nfts.map(async (item) => {
            let token_id = item['token_id']
            if (token_id === newlyStaked) {
                setNewlyStaked(null)
                //do nothing
            }
            else {
                let url = ipfs + ipfsURI + token_id + ".png";
                item['metadata'] = "{\"image\":\"" + url + "\"}";
                nftsarr.push(item);
            }
        })
        if (nftsarr.length === 0) {
            setHasNFTs(false);
        }
        else {
            setHasNFTs(true);
        }
        setNFTsArray(nftsarr);
        setLoading(false);
    }

    return (

        <div>
            <Toggle />
            <ReloadContext.Provider value={[reload, setReload]}>
                {
                    <>
                        {
                            !loading ?
                                <div>
                                    <div>
                                        {hasNFTs ?
                                            <ButtonGroup className="buttonToggle">
                                                {radios.map((radio, idx) => (
                                                    <ToggleButton
                                                        size="sm"
                                                        className="text"
                                                        key={idx}
                                                        id={`radio-${idx}`}
                                                        type="radio"
                                                        variant={'outline-warning'}
                                                        name="radio"
                                                        value={radio.value}
                                                        checked={radioValue === radio.value}
                                                        onChange={(e) => {
                                                            setRadioValue(
                                                                e.currentTarget.value)
                                                        }}
                                                    >
                                                        {radio.name}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                            :
                                            <>
                                            </>
                                        }
                                    </div>
                                    {
                                        NFTsArray != null && hasNFTs ?

                                            <div className="outline NFTContainer">

                                                {
                                                    NFTsArray.map(
                                                        (nft) => {
                                                            return (<NFTComponent tokenId={nft['token_id']} imageSrc={JSON.parse(nft['metadata'])['image']} duration={radioValue} newlyStakedState={setNewlyStaked}></NFTComponent>)
                                                        }
                                                    )
                                                }
                                            </div>

                                            :
                                            <div className="NFTContainer text">
                                                <h1>You Don't own any santafloki NFTs</h1>
                                                {/* <Button onClick={mint} className="inline" variant="danger text">MINT</Button> */}
                                            </div>
                                    }
                                </div>
                                :
                                <>
                                    <h1 className="text" style={{ marginTop: '30px' }}>Loading....</h1>
                                </>
                        }
                    </>
                }
            </ReloadContext.Provider>
        </div>

    )
}

export default NFTsManager;
import React, { useEffect, useState, useContext } from 'react';
import Countdown from 'react-countdown';
import { AppContext, ProviderContext } from '../App';
import Loading from '../Assets/images/outline.png'
import { NFTInfo } from './StakedManager';
import { ethers } from 'ethers';
import { useSigner } from 'wagmi';

const NFTComponentStake = (props) => {
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState(null);
    const tokenId = props.tokenId;
    const [stakingContractAddress, stakingContractABI, , ] = useContext(AppContext)
    const [provider, , , , ,] = useContext(ProviderContext);
    const [, setTokenIDStats, , setStakeDuration, , setStakeReward, , setShowDetails, reload, setReload, rewardEarned, setRewardEarned] = useContext(NFTInfo)
    const [localStakeDuration, setLocalStakeDuration] = useState('...')

    function onSelect() {
        setTokenIDStats(props.tokenId)
        setStakeDuration(localStakeDuration)
        setStakeReward((props.hourlyReward) * (parseInt(localStakeDuration.split(' ')[0]) * 24))
        setShowDetails(true)
    }

    const { data: signer, isLoading } = useSigner()

    useEffect(async () => {
        if (time == null) {
            await getTime()
        }
        if (localStakeDuration === '...') {
            await getStakeDuration()
        }
    }, [loading, provider, isLoading]);


    async function getTime() {

        //give signer for msg.sender to work
        if (window.ethereum) {
            var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, provider.getSigner());
        }
        else {
            var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
        }
        var result = await contract.getEndTime(props.tokenId);
        result = ethers.utils.formatUnits(result, 0);
        result = result - (Date.now() / 1000);
        if (result <= 0) {
            result = 0;
        }
        setTime(result);
        setLoading(false);
    }

    async function getStakeDuration() {
        if (window.ethereum) {
            var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, provider.getSigner());
        }
        else {
            var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
        }
        var result = await contract.getStakeDuration(tokenId)
        //seconds to days
        setLocalStakeDuration(((result.toNumber()) / 86400).toString() + " Days")
    }

    async function withdrawStake() {
        setLoading(true)
        try {
            if (window.ethereum) {
                var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, provider.getSigner());
            }
            else {
                var contract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            }
            let tx = await contract.withdrawStake(tokenId)
            var result = await tx.wait()
            if (result['status'] === 1) {
                setRewardEarned(true)
                setReload(!reload)
            }
            else {
                setLoading(false)
                delayedResetText()
            }
        }
        catch {
            setTime(null)
            setLoading(false)
            delayedResetText()
        }
    }

    async function delayedResetText() {
        var e = document.getElementById('btn')
        if (e == null) {
            return
        }
        e.innerHTML = "Failed!"
        await setTimeout(() => {
            e.innerHTML = 'Withdraw Stake'
        }, 4000);
    }


    return (
        <section>
            <div>
                <img className="NFTComponent" onClick={onSelect}
                    src={props.imageSrc} onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = Loading
                    }}></img>
            </div>
            <div>
                {
                    !loading ?
                        <>
                            {
                                time > 0 ?
                                    <Countdown className="text" date={Date.now() + time * 1000}>
                                        <button id='btn' className='button' onClick={(e) => { withdrawStake() }}>Withdraw Stake</button>
                                    </Countdown>
                                    :
                                    <button id='btn' className='button' onClick={(e) => { withdrawStake() }}>Withdraw Stake</button>
                            }
                        </>
                        :
                        // spinner
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only"></span>
                        </div>
                }
            </div>
        </section>
    );

}

export default NFTComponentStake;
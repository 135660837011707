import logov2 from '../Assets/images/logo.png'
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AppContext, ProviderContext } from "../App";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { watchAccount } from '@wagmi/core'

const Navbar = () => {

    const account = useAccount({
        async onConnect({ address, connector, isReconnected}) {
            console.log('wow connected')
            if (!window.ethereum) {
                console.log('wow connected')
                // var provider = await connector.getProvider() 
                var chainID = await connector.getChainId()
                setIsChainCorrect(chainID == correctChainWC)
                setEthAddress(address)
                setConnected(true)
                navigate('/stake', { replace: true })
            }
        }
    })

    const [stakingContractAddress,stakingContractABI,nftContractAddress,nftContractABI,eth,setEth,ethAddress,setEthAddress] = useContext(AppContext);
    const [, setProvider, , setIsChainCorrect, connected, setConnected] = useContext(ProviderContext);
    const navigate = useNavigate()
    const correctChain = '0x38'
    const correctChainWC = 56

    // const correctChain = '0x61'
    // const correctChainWC = 97
    
    const { open } = useWeb3Modal()

    const unwatch = watchAccount(
        (accountObj) => {
            if (!window.ethereum&&connected) {
                if (accountObj.address && account.address!=ethAddress) {
                    console.log(account)
                    setEthAddress(accountObj.address)
                }
                else if(accountObj.isDisconnected){
                    setConnected(false)
                }
            }
        }
    )

    try {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountChange);
            window.ethereum.on('chainChanged', handleChainChange)
        }
    }
    catch (err) {
        console.log(err)
    }

    async function walletConnectLogin() {
        open()
    }



    const login = async (e) => {
        e.target.innerHTML = "Connecting...";
        e.target.style.backgroundColor = "#c70303";
        if (!connected) {
            try {
                if (window.ethereum == null) {
                    walletConnectLogin()
                }
                else {
                    await metamaskLogin()
                }
            }
            catch (err) {
                e.target.value = "Failed !"
                // delayedReload()
            }
        }
        else {
            navigate('/', { replace: true })
            logout();
        }
    }

    async function metamaskLogin() {

        var provider = new ethers.providers.Web3Provider(window.ethereum)
        var accounts = await provider.send('eth_requestAccounts', [])

        if (accounts.length > 0) {

            var chainID = await window.ethereum.request({ method: 'eth_chainId' })
            setIsChainCorrect(chainID == correctChain)
            setEthAddress(accounts[0])
            setProvider(provider)
            setConnected(true)
            navigate('/stake', { replace: true })

        }
    }

    function handleAccountChange(accounts) {
        //prevents first login refresh
        if (!connected) {
            return
        }
        if (accounts.length === 0) {
            navigate('/', { replace: true })
            logout();
            window.location.reload();
        }
        else {
            navigate('/', { replace: true })
            logout();
            window.location.reload();
        }
    }

    function handleChainChange(chainId) {
        window.location.reload()
    }

    function logout() {
        if (window.ethereum) {
            navigate('/', { replace: true })
            window.location.reload()
            setConnected(false)
        }
        else {
            open()
        }
    }


    const popover = (
        <Popover id="addressPopover" className="addressPop accountText">
            <svg width={20} height={20} style={{ verticalAlign: "middle" }}>
                <circle cx={10} cy={10} r={5} fill="#1ddb16"></circle>
            </svg>{ethAddress}
        </Popover>
    );

    return (
        <nav className="navbar navbar-dark nav">
            <a className="navbar-brand text textheader" style={{ marginLeft: "12px" }}>
                <img src={logov2} className="headerLogo"></img>
                SantaFloki Staking
                <h5 style={{ position: 'absolute', left: '70px', top: '40px' }} className="subheading">Anniversary Edition</h5>
            </a>
            {connected ?
                <div className="form-inline">
                    <button className="button accountText address">
                        <svg width={20} height={20} style={{ verticalAlign: "middle" }}>
                            <circle cx={10} cy={10} r={5} fill="#1ddb16"></circle>
                        </svg>
                        {ethAddress}
                    </button>
                    <OverlayTrigger overlay={popover} placement="bottom" trigger="hover">
                        <button className="button login auth" onClick={() => {
                            logout()
                        }
                        }>

                        </button>
                    </OverlayTrigger>

                </div>
                :
                <>
                    <button className="button login" onClick={(e) => login(e)}>Connect Wallet</button>
                </>
            }

        </nav>
    );
}

export default Navbar;
import { Nav } from "react-bootstrap";
import { useEffect } from "react";
import React from "react";

const Toggle = () => {

    useEffect(() => {
        setActiveToggle();
      },[]);
  
      function setActiveToggle(){
          const url = window.location.href;
          var arr = url.split('/')
          if(arr[arr.length-1]==='stake'){
            document.getElementById('stake').classList.toggle('navButtonFocus')
            document.getElementById('staked').classList.toggle('navButton')
          }
          else{
            document.getElementById('staked').classList.toggle('navButtonFocus')
            document.getElementById('stake').classList.toggle('navButton')
          }
        } 

    return(
        <Nav className='navButtonContainer'x defaultActiveKey='/#/stake'>
                <Nav.Item>
                  <Nav.Link id='stake' className='text' href='#/stake'>
                      NFTs  
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link id='staked' className='text' href='#/staked'>
                      Staked NFTs  
                  </Nav.Link>
                </Nav.Item>
        </Nav>
    );
} 
export default Toggle;
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import Token  from '../Assets/images/header.png' 
import { ethers } from 'ethers';


const Stats = () => {
    const [stakingContractAddress,stakingContractABI,nftContractAddress,nftContractABI,eth,setEth,ethAddress,setEthAddress] = useContext(AppContext)
    const [loading,setLoading] = useState(true);
    const [count,setCount] = useState();
    const [total,] = useState(500);
    const [rewardedTokens,setRewardedTokens] = useState("... ");
    let providerlink = 'https://bsc-dataseed.binance.org/'
    // let providerlink = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
    //async function get 
    useEffect(async()=>{
        if(!count){
            await getStaked();
            await getRewardsGiven();
        }
    },[loading]);

    function parseReward(reward){
        var length = reward.toString().length
        if(length<4){
            return reward.toString()
        }
        else if(length>=4 && length<7){
            return (reward/1000).toString() + 'K'
        }
        else if(length>=7 && length<10){
            return Math.round((reward/1000000),3).toString() + 'M'
        }
        else if(length>=10 && length<13){
            return (reward/1000000000).toString() + 'B'
        }
    }

    async function getStaked(){
        var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,ethers.providers.getDefaultProvider(providerlink));
        let resultMultiCount = await contract.stakeCount();
        setCount(resultMultiCount.toNumber())
        setLoading(false);
    }

    function getPercentAge(){
        return ((count/total)*100).toFixed(2);
    }

    async function getRewardsGiven(){
        let contract = new ethers.Contract(stakingContractAddress,stakingContractABI,ethers.providers.getDefaultProvider(providerlink));
        let result = await contract.totalRewardReceived()
        result= parseInt(ethers.utils.formatUnits(result.toString(),4))
        let reward = parseReward((result).toString())
        setRewardedTokens(reward)
    }

return(
    <div>
    <div className='containerFull'>
        <div className='containerstats'>
            {!loading?
            <div>
            <h1 className='text stats' style={{display:"inline-block"}}>{count.toString()} of {total.toString()}</h1>
            <h1 className='text' style={{display:"inline-block",fontSize:"20px",paddingLeft:"5px"}}>NFTs</h1>
            <h1 className='text'>Staked</h1>
            </div>:
            <div>
            <h1 className='text'>Loading...</h1>
            </div>
            }
        </div>
        
        <div className='vLine'></div>
        
        <div className='containerstats'>
            {!loading?
            <div>
            <h1 className='text stats' style={{display:"inline-block"}}>{getPercentAge().toString()} %</h1>
            <h1 className='text' style={{display:"inline-block",fontSize:"20px",paddingLeft:"5px"}}>of NFTs</h1>
            <h1 className='text'>Staked</h1>
            </div>:
            <h1 className='text'>Loading...</h1>
            }
        </div>

    </div>
    <div style={{marginTop:'50px'}} className='containerstats'>
        {!loading?
        <div>
        <h1 className='text stats' style={{display:"inline-block"}}>{rewardedTokens}<img style={{marginLeft:'10px',width:'30px'}} src={Token}/></h1>
        <h1 className='text' style={{display:"inline-block",fontSize:"20px",paddingLeft:"5px"}}>HoHoHo</h1>
        <h1 className='text'>Rewarded</h1>
        </div>:
        <h1 className='text'>Loading...</h1>
        }
    </div>
</div>
);
}

export default Stats;
import React, { useContext } from 'react'
import NFTsManager from './NFTsManager';
import Stats from './Stats'
import { Route, Routes } from 'react-router-dom';
import StakedManager from './StakedManager';
import { ProviderContext } from '../App';

const Body = () => {

    const [,,isChainCorrect,setIsChainCorrect,connected,setConnected] = useContext(ProviderContext);
    return(
      <div>
        {((!connected))?
          <div>
            <Stats/>
            <h1 className="text middleAlmostCenter textbg">Please Connect Your Wallet</h1>
          </div>
          :
          <>
          {
            isChainCorrect!=null?
            <>
            {isChainCorrect?
              <Routes>
                <Route exact path='/stake' element={<NFTsManager/>}/>
                <Route path='/staked' element={<StakedManager/>}/>
              </Routes>
              :
              <h1 style={{marginTop:'20px'}} className="text">Incorrect Chain Please Switch To BSC Mainnet</h1>
            }
            </>
            :
            <>
            <h1 style={{marginTop:'20px'}} className="text">Loading..</h1>
            </>
          }
          </>
        }
      </div>
    );
}

export default Body;
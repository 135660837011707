import React,{useEffect, useState, useContext} from 'react';
import { AppContext, ProviderContext } from '../App';
import Loading from '../Assets/images/outline.png'
import { ReloadContext } from './NFTsManager';
import { ethers } from 'ethers';
import { useSigner } from 'wagmi';

const NFTComponent = (props) => {

    const [approval,setApproval] = useState(null);
    const [loading,setLoading] =useState(true);

    const tokenId=props.tokenId;

    const [provider,] = useContext(ProviderContext);
    const [stakingContractAddress,stakingContractABI,nftContractAddress,nftContractABI,,] = useContext(AppContext);
    const [reload,setReload] = useContext(ReloadContext)
    
    const { data: signer ,isLoading} = useSigner()

    useEffect(async()=>{
        checkApproval()
    },[approval,provider,isLoading]);

    async function checkApproval(){
        try{
        if(approval==null){
            if(window.ethereum){
                var contract = new ethers.Contract(nftContractAddress,nftContractABI,provider)
            }
            else{
                var contract = new ethers.Contract(nftContractAddress,nftContractABI,signer)    
            }
            var result = await contract.getApproved(props.tokenId);
            if(result===stakingContractAddress){
               setLoading(false)
               setApproval(true)
            }
            else{
                setLoading(false)
                setApproval(false)
            }
        }
    }catch{
        console.log('approval failed')
    }
    }

    async function addStake(e){
        if(window.ethereum){
            var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,provider.getSigner())
        }
        else{
            var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,signer)
        }
        if(e.target.innerHTML === 'Staking...'){
            return
        }
        e.target.innerHTML = 'Staking...'
        try{
            let tx = await contract.addStake(tokenId,props.duration)
            setLoading(true);
            let response = await tx.wait()
            if(response['status']===1){
                setTimeout(
                    ()=>{
                        props.newlyStakedState(tokenId)
                        setReload(!reload)
                    },1
                )
            }
            else{
                delayedResetText(false)
            }
        }  
        catch{
            delayedResetText(false)
        }
    }

    async function approve(e){
        if(e.target.innerHTML!=='Approve'){
            return
        }
        e.target.innerHTML = 'Approving...'
        try{
            let tx;
            if(window.ethereum){
                tx= await new ethers.Contract(nftContractAddress,nftContractABI,provider.getSigner()).approve(stakingContractAddress,tokenId);
            }
            else{
                tx= await new ethers.Contract(nftContractAddress,nftContractABI,signer).approve(stakingContractAddress,tokenId);
            }
            setLoading(true)
            let receipt=await tx.wait();
            if(receipt["status"]===1){
                setApproval(true);
                setLoading(false)
            }
            else{
                setApproval(false)
                setLoading(false)
                delayedResetText(true)
            };
        }
        catch(err){
            console.log("Error "+err)
            setApproval(false)
            setLoading(false)
            delayedResetText(true)
        }

    }

    async function delayedResetText(bool){
        var e = document.getElementById('btn')
        e.innerHTML = 'Failed!'
        await new Promise(
            ()=>{
                setTimeout(() => {
                    if(bool){
                        e.innerHTML = "Approve" 
                    }
                    else{
                        e.innerHTML = "Stake" 
                    }
                }, 2000)
            }
        )
    }


        return(
        <section>
            <div>
            <img className="NFTComponent"
             src={props.imageSrc} onError={({currentTarget})=>{
                currentTarget.onerror=null
                currentTarget.src=Loading
            }}></img>
            </div>
            <div>
            {
                approval === true ?
                
                    <div>
                        {!loading?
                        <button id='btn' className='button' onClick={(e)=>{addStake(e)}}>STAKE</button>
                        :
                        <div className="spinner-border text-danger" role="status">
                            <span className="sr-only"></span>
                        </div>
                        }
                    </div>
                    :
                    <>
                    {loading?
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only"></span>
                    </div>
                    :
                    <button id='btn' className='button' style={{backgroundColor:'red'}} onClick={(e)=>approve(e)}>Approve</button>    
                    }
                    </>
            }
            </div>
           
        </section>
        );
        
}

export default NFTComponent;
import React, { useContext } from "react";
import { NFTInfo } from "./StakedManager";
import logo from "../Assets/images/header.png"

const StakeStats = () =>{
    const [tokenIDStats,,stakeDuration,,stakeReward,,showDetails] = useContext(NFTInfo)

    return(
        <div className="stakeDetails text">
            {showDetails?
            <>           
            <h4>Token #: {tokenIDStats}</h4>
            <h4>Stake Duration: {stakeDuration}</h4>
            <h4>Stake Reward: {stakeReward}<img style={{width:'30px'}} src={logo}/></h4>
            </>:
            <h2>
            Click on NFT to view its details!
            </h2>
            }
        </div>
    );
}

export default StakeStats;
import React, { createContext } from "react";
import { useEffect } from "react";
import { useContext,useState } from "react";
import { AppContext, ProviderContext, } from "../App";
import NFTComponentStake from "./NFTComponentStake";
import StakeStats from "./StakeStats";
import Toggle from "./Toggle";
import { ethers } from "ethers";
import { useSigner } from "wagmi";

export const NFTInfo = createContext()

const StakedManager = () => {

    const{data:signer} = useSigner()

    const [tokenIDStats,setTokenIDStats] = useState()
    const [stakeDuration,setStakeDuration] = useState('...')
    const [stakeReward,setStakeReward] = useState('...')
    const [rewardPerHour,setRewardPerHour] = useState(null)
    const [showDetails,setShowDetails] = useState(false)
    const [reload,setReload] = useState(false)
    const [rewardEarned,setRewardEarned] = useState(false) 
    
    const [provider,] = useContext(ProviderContext)
    const [stakingContractAddress,stakingContractABI,,,,,ethAddress,,,] = useContext(AppContext)
    const [hasStakedNFTs,setHasStakedNFTs] = useState(null)
    const [loading,setLoading] = useState(false)
    const [stakeNFTsArray,setStakedNFTsArray] = useState([])

    const ipfs="https://santafloki.mypinata.cloud/ipfs/";
    
    const ipfsURI = "QmYTZJgpMdbHuGJbAxSTwnwHbaMr98gU4RNqUdxbH6NnAW/"
  
    useEffect(async() => {
        if(provider){
                await getStakedNFTs()
            if(rewardPerHour==null){
                await getRewardPerHour()
            }
        }
        if(rewardEarned===true){
            resetReward()
        }
    }, [provider,reload]);

    async function resetReward(){
        await setTimeout(()=>{
            setRewardEarned(false)
        },3000)
    }

    async function getRewardPerHour(){
        if(window.ethereum){
            var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,provider)
        }
        else{
            var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,signer)
        }
        var response = await contract.getRewardPerHour()
        setRewardPerHour(response.toNumber())
    }

    async function getStakedNFTs(){
        setLoading(true)
        var response = []
        try{
            if(window.ethereum){
                var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,provider)
            }
            else{
                var contract = new ethers.Contract(stakingContractAddress,stakingContractABI,signer)
            }
            response = await contract.getStakedTokenIds(ethAddress)
        }
        catch{
            console.log("No NFTs in new contract")
        }
        let oldNFT = null;
        if(!response.length===0&&oldNFT==null){
            setLoading(false)
            return
        }
        else if(oldNFT!=null){
            response = await copyToUnfreezedArray(response)
            response.push(oldNFT)
        }
        var arr= await hexToNumber(response)
        makeImageURI(arr,oldNFT)
    }

    async function copyToUnfreezedArray(response){
        let arr = []
        await response.map((item)=>{
            arr.push(item)
        })
        return arr
    }

    async function hexToNumber(arr){
        
        arr = arr.map(
            (number)=>{
                return number.toNumber()
            }
        );
        if(arr.length===0){
            setLoading(false)
        }
        return arr
    }

    async function makeImageURI(nfts,oldNFT){
        let old = oldNFT!=null?oldNFT.toNumber():-1
        const nftsarr=[];
        await nfts.map((item)=>{
            let token_id = item
            var dict = {}
                let url = ipfs+ipfsURI+token_id+".png";
                dict['image']=url;
                dict['token_id']=item
                if(token_id===old){
                    dict['old']=true
                }
                else{
                    dict['old']=false
                }
                nftsarr.push(dict);
            })
            if(nftsarr.length===0){
                setHasStakedNFTs(false);
            }
            else{
                setHasStakedNFTs(true);
                setTokenIDStats("...")
            }
            setLoading(false);
            setStakedNFTsArray(nftsarr);
    }

    return(
        <div>
            <NFTInfo.Provider value={[tokenIDStats,setTokenIDStats,stakeDuration,setStakeDuration,stakeReward,setStakeReward,showDetails,setShowDetails,reload,setReload,rewardEarned,setRewardEarned]}>
            <Toggle/>
                {
                    !loading?
                    <>
                    {
                        rewardEarned?
                        <h1 className="text">Reward sent to wallet</h1>:
                        <></>
                    }
                    {
                    hasStakedNFTs?
                    <>
                    <StakeStats></StakeStats>
                    <div className="NFTContainer">
                        {stakeNFTsArray.map(
                            (nft)=>{
                             return(<NFTComponentStake imageSrc={nft['image']} tokenId={nft['token_id']} old={nft['old']} hourlyReward={rewardPerHour} />)}
                        )} 
                    </div>
                    </>:
                    <div className="NFTContainer">
                        <h1 className="text" style={{marginTop:'30px'}}>You don't have any NFTs staked!</h1>
                    </div>

                    }
                    </>:
                    <h1 className="text" style={{marginTop:'30px'}}>Loading....</h1>
                }
            </NFTInfo.Provider>
        </div>
    );
}
export default StakedManager;